import React, { useEffect } from "react";
import Layout from "../../components/Layout";

const Survey = () => {
  useEffect(() => {
    // Dynamically load the Typeform embed script
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Layout>
      <div data-tf-live="01JCRCHNSPP5MXV1V06HSTF29M"></div>
    </Layout>
  );
};

export default Survey;
